<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">{{ pageTitle }}</div>
            </div>

            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">{{ pageDesc }}</span>
            </div>
        </div>
        <!-- Здесь пока ничего нет -->
        <nothing-block v-if="!report.length && !load"/>

        <section class="container fin-report__container">
            <div class="fin-report" v-for="item in report">
                <div class="fin-report__header">
                    <div class="text-bold text-size-ml text-color-white">
                        Отчёт с {{ item.datetime_from }} по {{ item.datetime_to }} на сумму
                        {{ number_format(getArchObjReportSum(item)) }} <span
                        class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                </div>
                <div class="fin-report__inner">
                    <div class="fin-report__elem">
                        <div class="fin-report__elem-row">
                            <div class="name">Объектов:</div>
                            <div class="elem objects" @click="showObjects[item.id] = !showObjects[item.id]">{{ item.objects_count }}
                                из {{ item.objects_count }}
                            </div>
                            <div class="comment">Здесь находятся только объекты, оплаченные наличными</div>
                        </div>
                        <div class="fin-report__objects" :class="{'showed': showObjects[item.id]}">
                            <div class="item" v-for="obj in item.objects">
                                <div class="check disabled">
                                    <input type="checkbox" :id="'checkbox_' + obj.id" disabled checked>
                                    <label :for="'checkbox_' + obj.id"></label>
                                </div>
                                <div class="square-meters">
                                    {{ obj.square }} м<sup>2</sup>
                                </div>
                                <div class="sum">
                                    {{ number_format(obj.sum) }} <span
                                    class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                                </div>
                                <div class="object-name">
                                    {{ obj.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fin-report__elem">
                        <div class="fin-report__elem-row">
                            <div class="name">Общая сумма:</div>
                            <div class="elem">{{ number_format(getArchObjSum(item.objects)) }} <span
                                class="rouble">{{ $store.getters['options/setRouble'] }}</span></div>
                        </div>
                    </div>
                    <div class="fin-report__elem">
                        <div class="fin-report__elem-row">
                            <div class="name">Предоплата:</div>
                            <div class="elem">{{ number_format(getArchObjPrepaySum(item.objects)) }} <span
                                class="rouble">{{ $store.getters['options/setRouble'] }}</span></div>
                        </div>
                    </div>
                    <div class="fin-report__elem archive">
                        <div class="fin-report__elem-row" @click="showSpent[item.id] = !showSpent[item.id]">
                            <div class="name">Наличные:</div>
                            <div class="elem">{{ number_format(getArchObjClearSum(item.objects)) }} <span
                                class="rouble">{{ $store.getters['options/setRouble'] }}</span></div>
                        </div>
                        <div class="fin-report__expenses" :class="{showed: showSpent[item.id]}">
                            <div class="item" v-for="(spend, index) in item.spend">
                                <div class="sum">
                                    − {{ number_format(spend.sum) }} <span
                                    class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                                </div>
                                <div class="comment">
                                    {{ spend.comment }}
                                </div>
                            </div>
                            <!-- Удалить после того как обработаем старые Архивные отчеты вместе с getArchObjSalaries-->
                            <div class="item" v-for="(salary, index) in this.getArchObjSalaries(item.salaries)">
                                <div class="sum">
                                    − {{ number_format(salary.sum) }} <span
                                    class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                                </div>
                                <div class="comment">
                                    {{ salary.comment }}
                                </div>
                            </div>
                            <div class="item" v-for="(salary, index) in item.usersPayments">
                                <div class="sum">
                                    − {{ number_format(salary.sum) }} <span
                                    class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                                </div>
                                <div class="comment">
                                    {{ salary.comment }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fin-report__elem">
                        <div v-if="isNew(item)" class="fin-report__elem-row">
                            <div class="name text-bold">Сумма отчета:</div>
                            <div class="elem">{{ number_format(getArchObjClearLeft(item)) }} <span class="rouble">{{
                                    $store.getters['options/setRouble']
                                }}</span></div>
                        </div>
                        <div v-else class="fin-report__elem-row">
                            <div class="name text-bold">Сумма отчета:</div>
                            <div class="elem">{{ number_format(getArchObjReportSum(item)) }} <span class="rouble">{{
                                    $store.getters['options/setRouble']
                                }}</span></div>
                            <div class="comment">
                                (75% от остатка наличных в размере {{ number_format(getArchObjClearLeft(item)) }} ₽)
                            </div>
                        </div>
                    </div>
                    <div class="text-color-grey mt12">
                        <span class="text-italic" v-html="item.text"></span><br>
                        <span class="text-bold">Алексей Земсков, {{ item.datetime_text }}</span>
                    </div>
                </div>
            </div>
            <div class="fin-report --ghost" v-if="load">
                <div class="fin-report__header">
                    <div class="ghost-elem"></div>
                </div>
                <div class="fin-report__inner">
                    <div class="fin-report__elem" v-for="i in 5">
                        <div class="fin-report__elem-row">
                            <div class="name ghost-elem"></div>
                            <div class="elem ghost-elem"></div>
                            <div class="comment ghost-elem"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import {mapState} from 'vuex';
import NothingBlock from '@/components/NothingBlock.vue';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: "FinReportArchive",
    props: {},
    components: {
        NothingBlock
    },
    data: function () {
        return {
            btnType: 'normal',
            spendSending: false,
            showForm: false,
            objects: [],
            spend: {},
            text: {
                value: '',
                error: false,
                repeatError: false,
                errorText: '',
            },
            checkbox: {
                value: '',
                error: false,
                repeatError: false,
                errorText: '',
            },
            showObjects: {},
            showSpent: {},
            expense: {
                price: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                comment: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
            },
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            itemTopActive: state => state.menus.itemTopActive,
            itemSecondActive: state => state.menus.itemSecondActive,
            menuSecond: state => state.menus.itemsSecond,
            report: state => state.fin.report,
            load: state => state.fin.load,
            isLoadNextPage: state => state.fin.isLoadNextPage,
            pageNum: state => state.fin.page,
            menuList: state => state.menus.itemsTop,
        }),
        pageTitle() {
            let activeItem = this.itemSecondActive,
                title = '';

            if (activeItem.title) {
                title = activeItem.title;
            }

            return title;
        },
        pageDesc() {
            let activeItem = this.itemSecondActive,
                desc = '';

            if (activeItem.description) {
                desc = activeItem.description;
            }

            return desc;
        },
        checked_objects() {
            let res = [];
            for (let i in this.objects) {
                if (this.objects[i].checked) {
                    res.push(this.objects[i]);
                }
            }

            return res;
        },
        hasRight() {
            return this.report.r;
        },
        checked_objects_clear_left() {
            return (this.checked_objects_clear_sum - this.spent_sum);
        },
        report_sum() {
            return (this.checked_objects_clear_sum - this.spent_sum) - parseInt((this.checked_objects_clear_sum - this.spent_sum) * 0.25);
        },
        spent_sum() {
            let res = 0;

            for (let n in this.report.usersPayments) {
                res += parseInt(this.report.usersPayments[n].sum);
            }

            for (let i in this.report.spend) {
                res += parseInt(this.report.spend[i].sum);
            }

            return res;
        },
        /*objects_salaries() {
            let res = {};

            for (let i in this.report.salaries) {
                for(let n in this.report.salaries[i]) {
                    if (typeof(res[this.report.salaries[i][n].group]) == 'undefined') {
                        res[this.report.salaries[i][n].group] = {
                            sum: this.report.salaries[i][n].sum,
                            comment: this.report.salaries[i][n].comment,
                            request: this.report.salaries[i][n].request,
                        }
                    } else {
                        res[this.report.salaries[i][n].group]['sum'] += this.report.salaries[i][n].sum;
                    }
                }
            }

            return res;
        },*/
        checked_objects_clear_sum() {
            let res = 0;
            for (let i in this.checked_objects) {
                res += this.checked_objects[i].sum;
                res -= this.checked_objects[i].prepay;
            }

            return res;
        },
        checked_objects_sum() {
            let res = 0;
            for (let i in this.checked_objects) {
                res += this.checked_objects[i].sum;
            }

            return res;
        },
        checked_objects_prepay() {
            let res = 0;
            for (let i in this.checked_objects) {
                res += this.checked_objects[i].prepay;
            }

            return res * -1;
        },
    },
    beforeUnmount() {
        this.objects = [];
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.$store.commit('fin/setLoad', true);
        this.$store.commit('fin/setNewPage');
        setTimeout(() => {
            this.fetchData();
        }, 1500);
    },
    methods: {
        isNew(item) {
            const str = item.datetime_from;
            const [day, month, year] = str.split('.');
            const date = new Date(+year, +month, +day);
            const old = new Date('2022','04','22');
            return date>old;
        },
        getArchObjSum(objects) {
            let res = 0;
            for (let i in objects) {
                res += objects[i].sum;
            }

            return res;
        },
        getArchObjPrepaySum(objects) {
            let res = 0;
            for (let i in objects) {
                res += objects[i].prepay;
            }

            return res * -1;
        },
        getArchObjClearSum(objects) {
            let res = 0;
            for (let i in objects) {
                res += objects[i].sum;
                res -= objects[i].prepay;
            }

            return res;
        },
        getArchObjSalaries(salaries) {
            let res = {};

            if (typeof (salaries) !== 'undefined') {
                for (let i in salaries) {
                    for (let n in salaries[i]) {
                        if (typeof (res[salaries[i][n].group]) == 'undefined') {
                            res[salaries[i][n].group] = {
                                sum: salaries[i][n].sum,
                                comment: salaries[i][n].comment/* + ' ' + this.checked_objects[i].salaries[n].request*/,
                                request: salaries[i][n].request,
                            }
                        } else {
                            res[salaries[i][n].group]['sum'] += salaries[i][n].sum;
                            /*res[this.checked_objects[i].salaries[n].group]['comment'] += ' ' + this.checked_objects[i].salaries[n].request;*/
                        }
                    }
                }
            }

            return res;
        },
        getArchObjReportSum(item) {
            let clearSum = this.getArchObjClearSum(item.objects);
            let spentSum = this.getArchObjSpentSum(item);
            return (clearSum - spentSum) - parseInt((clearSum - spentSum) * 0.25);
        },
        getArchObjSpentSum(item) {
            let res = 0,
                salaries = this.getArchObjSalaries(item.salaries);

            for (let n in salaries) {
                res += parseInt(salaries[n].sum);
            }

            if(item.usersPayments){
                for (let n in item.usersPayments) {
                    res += parseInt(item.usersPayments[n].sum);
                }   
            }

            for (let i in item.spend) {
                res += parseInt(item.spend[i].sum);
            }

            return res;
        },
        getArchObjClearLeft(item) {
            return (this.getArchObjClearSum(item.objects) - this.getArchObjSpentSum(item));
        },
        send() {
            let error = false;
            if (this.text.value === '') {
                this.text.error = true;
                error = true;
            }

            if (!this.checked_objects.length) {
                this.checkbox.error = true;
                error = true;
            }

            if (!error) {
                this.$store.dispatch('fin/send', {
                    type: 'send',
                    text: this.text.value,
                    objects: this.checked_objects,
                }).then((response) => {
                    this.objects = [];
                    this.spend = [];
                    this.text.value = '';
                    this.text.error = false;
                    this.fetchData();
                });
            }

        },
        removed() {
            this.fetchData();
        },
        openExpense() {
            this.showForm = true;
            this.$nextTick(() => {
                this.$refs.expenseSum.$el.getElementsByClassName('inp-s')[0].focus();
            });
        },
        openRemoveExpense() {
            this.$store.dispatch('popups/open', {name: this.popups.removeExpense.name});
        },
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
        addExpense() {
            if (this.expense.price.value.length < 1) {
                this.expense.price.error = true;
                return;
            }
            if (this.expense.comment.value.length < 1) {
                this.expense.comment.error = true;
                return;
            }

            this.spendSending = true;
            this.$store.dispatch('fin/setSpend', {
                type: 'setSpend',
                sum: this.expense.price.value,
                comment: this.expense.comment.value
            }).then(() => {
                this.fetchData();
                this.closeForm();
                this.spendSending = false;
            });

        },
        closeForm() {
            this.showForm = false;
            this.expense.price.value = '';
            this.expense.comment.value = '';
        },
        removeExpense(spend) {
            this.spend = spend;
            this.openRemoveExpense();
        },
        removeError(form) {
            form.error = false;
        },
        handleScroll: function () {
            let scroll = window.scrollY;
            let viewport = window.innerHeight;
            let bodyHeight = document.body.clientHeight;

            if(bodyHeight-80 <= scroll + viewport && !this.load && this.isLoadNextPage) {
                this.fetchData();
            }
        },
        fetchData() {
                this.$store.dispatch('fin/getData', {type: 'archive'}).then(() => {

                }).catch((error) => {

                });
        },
    },
}
</script>
